// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-detail-js": () => import("./../../../src/components/templates/BlogDetail.js" /* webpackChunkName: "component---src-components-templates-blog-detail-js" */),
  "component---src-components-templates-project-info-js": () => import("./../../../src/components/templates/ProjectInfo.js" /* webpackChunkName: "component---src-components-templates-project-info-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-tips-index-js": () => import("./../../../src/pages/tips/index.js" /* webpackChunkName: "component---src-pages-tips-index-js" */)
}

